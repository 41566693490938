import { Form, useFormikContext } from "formik";
import classnames from "classnames";
import React, { memo, useMemo } from "react";

import {
  Button,
  FormikImageUploader,
  FormikInput,
  FormikAdvanceSelect,
  FormikImageUploaderContent,
} from "components";

import { IManagementProps } from "./GroupForm.types";
import { FIELD_NAMES, InitActionGroup } from "./GroupForm.constants";
import FormikTextarea from "components/FormikTextarea";

import FormikAutoCompleteSelectField from "components/FormikAutoCompleteSelectField";
import { MAX_TEXT_LENGTH, MAX_TITLE_LENGTH } from "./GroupForm.schema";
import { FormikMultiSelectWithTime } from "./GroupFormSkillsField.component";

import Attachments from "modules/Attachments/Attachments";
import FormikTags from "components/FormikTags/FormikTags";
import { useQueryClient } from "react-query";
import { IAISuggestions, IFeedbackArea } from "types";
import { twMerge } from "tailwind-merge";
import { CONTENT_QUALITY_LEVELS } from "const";

interface AuthenticationPanelProps
  extends React.HTMLAttributes<HTMLDivElement>,
    IManagementProps {
  className?: string;
  onClose: () => void;
}
const options = [
  {
    value: "Existing Group",
    label: "Existing Group",
  },
  {
    value: "New Group",
    label: "New Group",
  },
];
function GroupForm(props: AuthenticationPanelProps) {
  const { errors, values, isSubmitting } = useFormikContext<InitActionGroup>();
  const queryClient = useQueryClient();
  const allMutations = queryClient
    .getMutationCache()
    .findAll({ mutationKey: ["createAIAnalyticsGroups"] });
  const latestAIMutation = useMemo(
    () =>
      allMutations && allMutations.length > 0
        ? //@ts-ignore
          allMutations[allMutations.length - 1]?.state?.data?.data
        : //@ts-ignore
          allMutations[0]?.state?.data?.data,
    [allMutations]
  ) as IAISuggestions | undefined;

  const moreFeedback = latestAIMutation?.more_feedback?.quality;
  const defaultValueForProblemAreaWithMoreFeedback: IFeedbackArea = {
    input_name: "",
    quality: "Poor" as CONTENT_QUALITY_LEVELS,
    user_input: "",
    feedback: "",
    suggested_texts: [],
  };
  return (
    <Form className="flex flex-col flex-1">
      <div className="px-4 sm:px-20 flex-1 eleven-seventh-step  flex flex-col gap-2">
        {props.showIssueFields && (
          <>
            <FormikAdvanceSelect
              isSearchable={false}
              options={[
                { label: "Private", value: "true" },
                { label: "Public", value: "false" },
              ]}
              closeMenuOnSelect
              styles={{
                indicatorsContainer: () => ({
                  display: "none",
                }),
                menuList: (provided) => ({
                  ...provided,
                  padding: "0px",
                }),
              }}
              placeholder="Select privacy setting"
              label="Privacy Settings"
              name={FIELD_NAMES.IS_PRIVATE}
              message={
                "Should group discussions be private or open for the public to view? Only group members can comment"
              }
            />
            <FormikInput
              checkAIContentQuality={!!latestAIMutation}
              AISuggestionProblemArea={
                latestAIMutation?.feedback_areas?.find(
                  (problemArea) =>
                    problemArea.input_name.toLocaleLowerCase() === "title"
                ) ||
                (moreFeedback
                  ? {
                      ...defaultValueForProblemAreaWithMoreFeedback,
                      input_name: FIELD_NAMES.TITLE,
                    }
                  : undefined)
              }
              AISuggestionFieldName={FIELD_NAMES.SUGGESTED_TITLE}
              autoComplete="nope"
              label="Title"
              name={FIELD_NAMES.TITLE}
              placeholder="Title"
              message={
                <div className="flex justify-between text-gray-400  font-light text-sm">
                  <span>What is the title/name of your group?</span>
                  <span
                    className={classnames({
                      "text-red-400": errors?.title === "max",
                    })}
                  >{`${values?.title?.length}/${MAX_TITLE_LENGTH}`}</span>
                </div>
              }
            />
            <FormikInput
              forUrlInput
              name={FIELD_NAMES.CONTACT_URL}
              label="Website"
              placeholder="www.example.com"
              message={"Website, if available"}
            />
            <FormikTextarea
              checkAIContentQuality={!!latestAIMutation}
              AISuggestionProblemArea={
                latestAIMutation?.feedback_areas?.find(
                  (problemArea) =>
                    problemArea.input_name.toLocaleLowerCase() === "goals"
                ) ||
                (moreFeedback
                  ? {
                      ...defaultValueForProblemAreaWithMoreFeedback,
                      input_name: FIELD_NAMES.DESCRIPTION,
                    }
                  : undefined)
              }
              AISuggestionFieldName={FIELD_NAMES.SUGGESTED_DESCRIPTION}
              autoComplete="nope"
              label="Goals"
              name={FIELD_NAMES.DESCRIPTION}
              placeholder="Goals"
              className="mt-4"
              showOnlyCustomMessage={errors?.description === "max"}
              message={
                <div className="flex justify-between text-gray-400  font-light text-sm">
                  <span>
                    Please add more details about the goals and objectives of
                    this group
                  </span>
                  <span
                    className={classnames({
                      "text-red-400": errors?.description === "max",
                    })}
                  >{`${values?.description?.length}/${MAX_TEXT_LENGTH}`}</span>
                </div>
              }
            />
            <FormikTextarea
              checkAIContentQuality={!!latestAIMutation}
              AISuggestionProblemArea={
                latestAIMutation?.feedback_areas?.find(
                  (problemArea) =>
                    problemArea.input_name.toLocaleLowerCase() === "schedule"
                ) ||
                (moreFeedback
                  ? {
                      ...defaultValueForProblemAreaWithMoreFeedback,
                      input_name: FIELD_NAMES.SCHEDULE,
                    }
                  : undefined)
              }
              AISuggestionFieldName={FIELD_NAMES.SUGGESTED_SCHEDULE}
              autoComplete="nope"
              label="Schedule"
              name={FIELD_NAMES.SCHEDULE}
              placeholder="Schedule"
              className="mt-1"
              showOnlyCustomMessage={errors?.schedule === "max"}
              message={
                <div className="flex justify-between text-gray-400  font-light text-sm">
                  <span>
                    Please add some info about the schedule of any regular group
                    activities
                  </span>
                  <span
                    className={classnames({
                      "text-red-400": errors?.schedule === "max",
                    })}
                  >{`${values?.schedule?.length}/${MAX_TEXT_LENGTH}`}</span>
                </div>
              }
            />
            <FormikTextarea
              checkAIContentQuality={!!latestAIMutation}
              AISuggestionProblemArea={
                latestAIMutation?.feedback_areas?.find(
                  (problemArea) =>
                    problemArea.input_name.toLocaleLowerCase() === "contact"
                ) ||
                (moreFeedback
                  ? {
                      ...defaultValueForProblemAreaWithMoreFeedback,
                      input_name: FIELD_NAMES.CONTACT,
                    }
                  : undefined)
              }
              AISuggestionFieldName={FIELD_NAMES.SUGGESTED_CONTACT}
              autoComplete="nope"
              label="Contact"
              name={FIELD_NAMES.CONTACT}
              placeholder="Contact"
              className="mt-1"
              showOnlyCustomMessage={errors?.contact === "max"}
              message={
                <div className="flex justify-between text-gray-400  font-light text-sm">
                  <span>
                    Key contact information such as phone number, email,
                    physical address
                  </span>
                  <span
                    className={classnames({
                      "text-red-400": errors?.contact === "max",
                    })}
                  >{`${values?.contact?.length}/${MAX_TEXT_LENGTH}`}</span>
                </div>
              }
            />
            <FormikAutoCompleteSelectField
              options={options}
              isDisabled={false}
              disableOption={false}
              label="Status"
              name={FIELD_NAMES.STAGE}
            />

            <FormikImageUploader
              groupStyle
              name={FIELD_NAMES.LOGO}
              label="Group logo"
              userName={`${values.title}`}
              Content={FormikImageUploaderContent}
              className="flex flex-col items-start gap-2"
              classNames={{
                label: "flex justify-center mt-[10px] font-semibold",
              }}
            />

            <FormikMultiSelectWithTime
              formValues={values.skills}
              name={FIELD_NAMES.SKILLS}
            />

            <Attachments />
            <FormikTags
              fieldName={FIELD_NAMES.TAGS}
              label="Labels (Optional)"
            />
          </>
        )}
      </div>

      {!!errors?.validator! && (
        <div className="w-full px-12">
          <p className=" text-red-400 text-sm font-normal">
            {errors?.validator! as string}
          </p>
        </div>
      )}
      <div className="border-b border-gray-200 w-full h-[1px] mt-10" />
      <div className="mt-4 flex gap-4 justify-end px-4 sm:px-20">
        <Button
          isReverse
          type="button"
          className=" justify-center border"
          disabled={isSubmitting}
          onClick={props.onClose}
        >
          Cancel
        </Button>
        <button
          type="submit"
          disabled={isSubmitting || !!errors?.validator}
          className={twMerge(
            classnames(
              "w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-3 bg-quality-content-green text-base font-medium text-white hover:bg-quality-content-green focus:outline-none  sm:ml-3 sm:w-auto sm:text-sm"
            )
          )}
        >
          Review and Save
        </button>
      </div>
    </Form>
  );
}

export default memo(GroupForm);

import Heading from "components/Heading/Heading";
import { useMe, useQueryOptionsSetter, useSolution } from "hooks";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { APP_ROUTES } from "const";

import { StaticMapCard } from "./StaticMapCard";
import { SlideOver } from "components/SlideOver";
import { CommentsCard, GroupForm, SolutionForm } from "components";
import { SolutionsCard } from "./SolutionsCard";
import { ActionGroupsCard } from "./ActionGroupsCard";
import { AddActionGroup } from "modules/AddActionGroup";
import { Dialog, Switch } from "@headlessui/react";
import classnames from "classnames";
import { useQuery, useQueryClient } from "react-query";
import { IAISuggestions } from "types";

function Solution() {
  const navigate = useNavigate();
  const params = useParams<{ id: string }>();
  const { data: solutionsData } = useSolution({
    id: params.id,
    suspense: true,
  });
  const solution = solutionsData?.data;
  const issue = solutionsData?.data.issue;
  const user = solutionsData?.data.user;
  const { data: me } = useMe();

  const [solutionsForm, setSolutionsForm] = useState(false);
  const [groupForm, setGroupForm] = useState(false);
  const [toggleForms, setToggleForms] = useState(true);

  const onAddSolutions = useCallback(() => {
    if (solutionsData?.data.user?.id === me?.data.id) {
      setSolutionsForm(true);
    }
  }, [solutionsData?.data.user?.id, me?.data.id]);

  const onAddGroup = useCallback(() => {
    if (me?.data.id) {
      setGroupForm(true);
    }
  }, [me?.data.id]);

  const [searchParams, setSearchParams] = useSearchParams();
  const setQueryOptions = useQueryOptionsSetter(searchParams, setSearchParams);
  const editGroupId = searchParams.get("groupId") || "";
  useEffect(() => {
    if (editGroupId) {
      setGroupForm(true);
    }
  }, [editGroupId]);

  const isSoftDeleted = !!solution?.deletedAt;

  const [mutationCacheRerenderCounter, setMutationCacheRerenderCounter] =
    useState(0);

  const queryClient = useQueryClient();

  const subscribeCacheCallback = useCallback((queryCacheNotifyEvent) => {
    if (!queryCacheNotifyEvent) {
      return;
    }
    setMutationCacheRerenderCounter((prev) => prev + 1);
  }, []);

  queryClient.getMutationCache().subscribe(subscribeCacheCallback);
  const allAISolutionMutations = queryClient
    .getMutationCache()
    .findAll({ mutationKey: ["createAIAnalyticsSolution"] });
  const allAIGroupsMutations = queryClient.getMutationCache().findAll({
    mutationKey: ["createAIAnalyticsGroups"],
  });
  const latestSolutionAIMutation = useMemo(
    () =>
      allAISolutionMutations && allAISolutionMutations.length > 0
        ? //@ts-ignore
          allAISolutionMutations[allAISolutionMutations.length - 1]?.state?.data
            ?.data
        : //@ts-ignore
          allAISolutionMutations[0]?.state?.data?.data,
    [allAISolutionMutations]
  ) as IAISuggestions | undefined;
  const latestGroupsAIMutation = useMemo(
    () =>
      allAIGroupsMutations && allAIGroupsMutations.length > 0
        ? //@ts-ignore
          allAIGroupsMutations[allAIGroupsMutations.length - 1]?.state?.data
            ?.data
        : //@ts-ignore
          allAIGroupsMutations[0]?.state?.data?.data,
    [allAIGroupsMutations]
  ) as IAISuggestions | undefined;

  const moreFeedbackQualitySolution = useMemo(() => {
    if (!latestSolutionAIMutation) {
      return undefined;
    }
    return latestSolutionAIMutation.more_feedback;
  }, [latestSolutionAIMutation]);

  const moreFeedbackQualityGroup = useMemo(() => {
    if (!latestGroupsAIMutation) {
      return undefined;
    }
    return latestGroupsAIMutation.more_feedback;
  }, [latestGroupsAIMutation]);

  return (
    <div className=" sm:mx-[5%] pt-8 pb-4 max-w-[1600px]">
      <div className="my-2 flex flex-col sm:flex-row gap-2 flex-wrap">
        <span
          onClick={() => {
            navigate(APP_ROUTES.ISSUES);
          }}
          className="text-[#606060] font-normal text-md cursor-pointer"
        >
          &gt; Issues
        </span>
        <span
          onClick={() => {
            navigate(APP_ROUTES.ISSUE + "/" + solution?.issue.id);
          }}
          className="text-[#606060] font-normal text-md cursor-pointer"
        >
          &gt; Issue: {solution?.issue.title}
        </span>
        <span
          onClick={() => {
            navigate(APP_ROUTES.SOLUTION_LIST);
          }}
          className="text-[#606060] font-normal text-md cursor-pointer"
        >
          &gt; Solutions
        </span>
        <span className="text-[#606060] font-normal text-md cursor-pointer">
          &gt; Solution: {solution?.title}
        </span>
      </div>
      <Heading type="h1">
        {solutionsData?.data.title}{" "}
        {isSoftDeleted && "(This solution was deleted)"}
      </Heading>
      <div className="grid pt-12 lg:grid-cols-2 gap-6">
        <SolutionsCard
          solution={solution}
          onEdit={onAddSolutions}
          allowEdit={user?.id === me?.data.id}
        />
        <StaticMapCard issue={issue} isSoftDeleted={!!solution?.deletedAt} />
        <ActionGroupsCard
          onEdit={onAddGroup}
          solution={params.id!}
          allowEdit={!!me?.data.id}
          isSoftDeleted={!!solution?.deletedAt}
        />
        <CommentsCard
          entityOwnerId={solution?.user.id}
          isSoftDeleted={!!solution?.deletedAt}
        />
      </div>
      <SlideOver
        id="solution-report"
        open={!!solutionsForm}
        close={useCallback(() => {
          setSolutionsForm(false);
          queryClient.getMutationCache().clear();
        }, [queryClient])}
        title="Add Solution"
        description={
          <div className="flex flex-col">
            <Dialog.Description className="text-sm  text-gray-500 font-light mt-[14px]">
              This information will be displayed publicly so be careful what you
              share.
            </Dialog.Description>
            {(latestSolutionAIMutation?.feedback_areas?.length || 0) > 0 ? (
              <Dialog.Description className="text-sm  text-red-500 font-light">
                Please review the highlighted sections for suggested
                improvements before we can save your post
              </Dialog.Description>
            ) : null}
            {moreFeedbackQualitySolution &&
            (moreFeedbackQualitySolution.quality === "Useless" ||
              moreFeedbackQualitySolution.quality === "Poor") ? (
              <Dialog.Description className="text-sm  text-red-500 font-light">
                {latestSolutionAIMutation?.more_feedback?.feedback}
              </Dialog.Description>
            ) : null}
          </div>
        }
      >
        <SolutionForm
          onClose={useCallback(() => {
            setSolutionsForm(false);
            queryClient.getMutationCache().clear();
          }, [queryClient])}
          onSuccess={useCallback(() => {
            setSolutionsForm(false);
            queryClient.getMutationCache().clear();
          }, [queryClient])}
          showSolutionFields
          showSolutionRatings={false}
        />
      </SlideOver>
      <SlideOver
        id="group-report"
        preventClose={true}
        position="right"
        open={!!groupForm}
        close={useCallback(() => {
          setGroupForm(false);
          setQueryOptions((val) => ({
            ...val,
            groupId: "",
          }));
          queryClient.getMutationCache().clear();
        }, [setQueryOptions, queryClient])}
        title="Create action group"
        description={
          <div className="flex flex-col">
            <Dialog.Description className="text-sm  text-gray-500 font-light mt-[14px]">
              This information will be displayed publicly so be careful what you
              share.
            </Dialog.Description>
            {(latestGroupsAIMutation?.feedback_areas?.length || 0) > 0 ? (
              <Dialog.Description className="text-sm  text-red-500 font-light">
                Please review the highlighted sections for suggested
                improvements before we can save your post
              </Dialog.Description>
            ) : null}
            {moreFeedbackQualityGroup &&
            (moreFeedbackQualityGroup.quality === "Useless" ||
              moreFeedbackQualityGroup.quality === "Poor") ? (
              <Dialog.Description className="text-sm  text-red-500 font-light">
                {latestGroupsAIMutation?.more_feedback?.feedback}
              </Dialog.Description>
            ) : null}
          </div>
        }
      >
        <>
          <div className="flex flex-row px-4 sm:px-20 pb-4 gap-2">
            <div className="text-gray-500 text-[14px]">
              Select Existing Group
            </div>
            <Switch
              checked={toggleForms}
              onChange={() => {
                setToggleForms((prev) => !prev);
              }}
              className="bg-[#659ae9] relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200"
            >
              <span
                aria-hidden="true"
                className={classnames(
                  toggleForms ? "translate-x-5" : "translate-x-0",
                  "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                )}
              />
            </Switch>
            <div className="text-gray-500 text-[14px]">Create New Group</div>
          </div>
          {toggleForms && (
            <GroupForm
              onClose={() => {
                setGroupForm(false);
                setQueryOptions((val) => ({
                  ...val,
                  groupId: "",
                }));
                queryClient.getMutationCache().clear();
              }}
              onSuccess={() => {
                setGroupForm(false);
                setQueryOptions((val) => ({
                  ...val,
                  groupId: "",
                }));
                queryClient.getMutationCache().clear();
              }}
            />
          )}
          {!toggleForms && <AddActionGroup solution={params.id!} />}
        </>
      </SlideOver>
    </div>
  );
}

export default Solution;

import { Form, useFormikContext } from "formik";
import classnames from "classnames";

import { Button, FormikInput } from "components";

import { IManagementProps } from "./SolutionForm.types";
import { FIELD_NAMES, SolutionInitial } from "./SolutionForm.constants";
import FormikTextarea from "components/FormikTextarea";

import { MAX_TEXT_LENGTH, MAX_TITLE_LENGTH } from "./SolutionForm.schema";

import Attachments from "modules/Attachments/Attachments";
import FormikTags from "components/FormikTags/FormikTags";
import { useQueryClient } from "react-query";
import { useMemo } from "react";
import { IAISuggestions, IFeedbackArea } from "types";
import { twMerge } from "tailwind-merge";
import { CONTENT_QUALITY_LEVELS } from "const";

interface AuthenticationPanelProps extends IManagementProps {
  className?: string;
  onClose: () => void;
}

function SolutionForm(props: AuthenticationPanelProps) {
  const { errors, values, isSubmitting } = useFormikContext<SolutionInitial>();
  const queryClient = useQueryClient();
  const allMutations = queryClient
    .getMutationCache()
    .findAll({ mutationKey: ["createAIAnalyticsSolution"] });
  const latestAIMutation = useMemo(
    () =>
      allMutations && allMutations.length > 0
        ? //@ts-ignore
          allMutations[allMutations.length - 1]?.state?.data?.data
        : //@ts-ignore
          allMutations[0]?.state?.data?.data,
    [allMutations]
  ) as IAISuggestions | undefined;

  const moreFeedback = latestAIMutation?.more_feedback?.quality;
  const defaultValueForProblemAreaWithMoreFeedback: IFeedbackArea = {
    input_name: "",
    quality: "Poor" as CONTENT_QUALITY_LEVELS,
    user_input: "",
    feedback: "",
    suggested_texts: [],
  };
  console.debug("latestAIMutation", latestAIMutation, !!latestAIMutation);
  return (
    <Form className=" flex flex-col flex-1 six-sixth-step">
      <div className="flex gap-2 flex-col px-4 sm:px-20 flex-1 ">
        {props.showSolutionFields && (
          <>
            <FormikInput
              checkAIContentQuality={!!latestAIMutation}
              AISuggestionProblemArea={
                latestAIMutation?.feedback_areas?.find(
                  (problemArea) =>
                    problemArea.input_name.toLocaleLowerCase() === "title"
                ) ||
                (moreFeedback
                  ? {
                      ...defaultValueForProblemAreaWithMoreFeedback,
                      input_name: FIELD_NAMES.TITLE,
                    }
                  : undefined)
              }
              AISuggestionFieldName={FIELD_NAMES.SUGGESTED_TITLE}
              autoComplete="nope"
              label="Title"
              name={FIELD_NAMES.TITLE}
              placeholder="Title"
              message={
                <div className="flex justify-between text-gray-400  font-light text-sm">
                  <span>Please add a descriptive title</span>
                  <span
                    className={classnames({
                      "text-red-400": errors?.title === "max",
                    })}
                  >{`${values?.title?.length}/${MAX_TITLE_LENGTH}`}</span>
                </div>
              }
            />

            <FormikTextarea
              checkAIContentQuality={!!latestAIMutation}
              AISuggestionProblemArea={
                latestAIMutation?.feedback_areas?.find(
                  (problemArea) =>
                    problemArea.input_name.toLocaleLowerCase() === "details"
                ) ||
                (moreFeedback
                  ? {
                      ...defaultValueForProblemAreaWithMoreFeedback,
                      input_name: FIELD_NAMES.DESCRIPTION,
                    }
                  : undefined)
              }
              AISuggestionFieldName={FIELD_NAMES.SUGGESTED_DESCRIPTION}
              autoComplete="nope"
              label="Details"
              name={FIELD_NAMES.DESCRIPTION}
              placeholder="Details"
              className="mt-1"
              showOnlyCustomMessage={errors?.description === "max"}
              message={
                <div className="flex justify-between text-gray-400  font-light text-sm">
                  <span>Please add some more details</span>
                  <span
                    className={classnames({
                      "text-red-400": errors?.description === "max",
                    })}
                  >{`${values?.description?.length}/${MAX_TEXT_LENGTH}`}</span>
                </div>
              }
            />

            <FormikTextarea
              checkAIContentQuality={!!latestAIMutation}
              AISuggestionProblemArea={
                latestAIMutation?.feedback_areas?.find(
                  (problemArea) =>
                    problemArea.input_name.toLocaleLowerCase() ===
                    "effectiveness"
                ) ||
                (moreFeedback
                  ? {
                      ...defaultValueForProblemAreaWithMoreFeedback,
                      input_name: FIELD_NAMES.EFFECTIVENESS,
                    }
                  : undefined)
              }
              AISuggestionFieldName={FIELD_NAMES.SUGGESTED_EFFECTIVENESS}
              autoComplete="nope"
              label="Effectiveness"
              name={FIELD_NAMES.EFFECTIVENESS}
              placeholder="Effectiveness"
              className="mt-1"
              showOnlyCustomMessage={errors?.effectiveness === "max"}
              message={
                <div className="flex justify-between text-gray-400  font-light text-sm">
                  <span>Why will this solution be effective?</span>
                  <span
                    className={classnames({
                      "text-red-400": errors?.effectiveness === "max",
                    })}
                  >{`${values?.effectiveness?.length}/${MAX_TEXT_LENGTH}`}</span>
                </div>
              }
            />
          </>
        )}
        <Attachments />
        <FormikTags fieldName={FIELD_NAMES.TAGS} label="Labels (Optional)" />
      </div>

      {
        //@ts-ignore
        !!errors?.validator! && (
          <div className="w-full px-12">
            <p className=" text-red-400 text-sm font-normal">
              {errors?.validator as string}
            </p>
          </div>
        )
      }
      <div className="border-b border-gray-200 w-full h-[1px] mt-10" />
      <div className="mt-4 flex gap-4 justify-end px-4 sm:px-20">
        <Button
          isReverse
          type="button"
          className=" justify-center border"
          disabled={isSubmitting}
          onClick={props.onClose}
        >
          Cancel
        </Button>
        <button
          type="submit"
          disabled={isSubmitting || !!errors?.validator}
          className={twMerge(
            classnames(
              "w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-3 bg-quality-content-green text-base font-medium text-white hover:bg-quality-content-green focus:outline-none  sm:ml-3 sm:w-auto sm:text-sm"
            )
          )}
        >
          Review and Save
        </button>
        {/* <Button
          type="submit"
          className="justify-center"
          disabled={isSubmitting || !!errors?.validator}
        >
          Save
        </Button> */}
      </div>
    </Form>
  );
}

export default SolutionForm;

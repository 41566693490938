import { Formik } from "formik";
import { memo } from "react";

import validationSchema from "./GroupForm.schema";
import { useInitialState, useSubmitHandler } from "./GroupForm.hooks";
import GroupForm from "./GroupForm.component";
import { IManagementProps } from "./GroupForm.types";
import { InitActionGroup } from "./GroupForm.constants";

interface IProps extends IManagementProps {
  className?: string;
  onSuccess: () => void;
  onClose: () => void;
}

function GroupFormContainer({
  className,
  onSuccess,
  onClose,
  showIssueFields = true,
  showRatings = true,
}: IProps) {
  return (
    <Formik<Partial<InitActionGroup>>
      enableReinitialize
      initialValues={useInitialState()}
      validationSchema={validationSchema()}
      onSubmit={useSubmitHandler(onSuccess, { showIssueFields, showRatings })}
    >
      <GroupForm
        className={className}
        onClose={onClose}
        showIssueFields={showIssueFields}
        showRatings={showRatings}
      />
    </Formik>
  );
}

export default memo(GroupFormContainer);

import { AxiosResponse } from "axios";
import { APP_ROUTES, ENDPOINTS } from "const";
import { FormikHelpers } from "formik";
import {
  useActionGroup,
  useActionGroupSkills,
  useMe,
  useSolution,
  useUpdateAttachments,
} from "hooks";
import { useCallback, useMemo } from "react";
import { useMutation, UseMutationOptions, useQueryClient } from "react-query";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import {
  FullActionGroupType,
  IActionGroupSkill,
  IAISuggestions,
  IAsset,
  IAttachment,
} from "types";
import { showErrorMessage, showSuccessMessage } from "utils";
import { IManagementProps } from "./GroupForm.types";
import {
  createActionGroup,
  createActionGroupSkills,
  updateActionGroup,
  updateActionGroupSkills,
} from "./GroupForm.services";
import { ActionGroupPayload, InitActionGroup } from "./GroupForm.constants";
import { createAISuggestionsGroups, IAIGroupsProps } from "services";

export function useInitialState() {
  const params = useParams<{ id: string }>();
  const { data: me } = useMe();
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const isOnActionGroupPage = location.pathname.includes(
    APP_ROUTES.ACTION_GROUP
  );
  const editGroupId = isOnActionGroupPage
    ? params.id
    : searchParams.get("groupId") || "";
  const { data: actionGroup } = useActionGroup({
    id: editGroupId,
    enabled: !!editGroupId,
    user: me?.data.id,
  });
  const { data: groupSkills } = useActionGroupSkills({
    id: actionGroup?.data.id,
    enabled: !!actionGroup,
  });

  return useMemo(() => {
    const init = new InitActionGroup(actionGroup?.data, groupSkills?.data);
    return init;
  }, [groupSkills?.data, actionGroup?.data]);
}

interface IAIAnalyticsGroupsProps
  extends Partial<UseMutationOptions<AxiosResponse<IAISuggestions>, any, any>> {
  id?: string | undefined;
}

export function useCreateAIAnalyticsGroups({
  ...rest
}: IAIAnalyticsGroupsProps) {
  const queryClient = useQueryClient();
  return useMutation<AxiosResponse<IAISuggestions>, any, any>(
    (group: IAIGroupsProps) => createAISuggestionsGroups({ ...group }),
    {
      mutationKey: ["createAIAnalyticsGroups"],
      onSuccess: (data) => {
        queryClient.setQueryData(["createAIAnalyticsGroups"], data.data || {});
      },
      ...rest,
    }
  );
}

interface IActionGroupsProps
  extends Partial<
    UseMutationOptions<AxiosResponse<FullActionGroupType>, any, any>
  > {
  id?: string | undefined;
}

interface IActionGroupsSkillsProps
  extends Partial<
    UseMutationOptions<AxiosResponse<IActionGroupSkill[]>, any, any>
  > {}

export function useCreateActionGroup({ ...rest }: IActionGroupsProps) {
  return useMutation<AxiosResponse<FullActionGroupType>, any, any>(
    (group: FullActionGroupType) => createActionGroup({ group }),
    {
      ...rest,
    }
  );
}

export function useUpdateActionGroup({
  id = "",
  ...rest
}: IActionGroupsProps & {
  id: string | undefined;
}) {
  return useMutation<AxiosResponse<FullActionGroupType>, any, any>(
    (group: FullActionGroupType) => updateActionGroup({ id, group }),
    {
      ...rest,
    }
  );
}

export function useCreateActionGroupSkill({
  ...rest
}: IActionGroupsSkillsProps) {
  return useMutation<AxiosResponse<IActionGroupSkill[]>, any, any>(
    (groupSkill: IActionGroupSkill[]) =>
      createActionGroupSkills({ groupSkill }),
    {
      ...rest,
    }
  );
}

export function useUpdateActionGroupSkill({
  ...rest
}: IActionGroupsSkillsProps) {
  return useMutation<AxiosResponse<IActionGroupSkill[]>, any, any>(
    (groupSkill: IActionGroupSkill[]) =>
      updateActionGroupSkills({ groupSkill }),
    {
      ...rest,
    }
  );
}

export function useSubmitHandler(
  success: () => void,
  manage: IManagementProps
) {
  const params = useParams<{ id: string }>();
  const location = useLocation();
  const isOnActionGroupPage = location.pathname.includes(
    APP_ROUTES.ACTION_GROUP
  );
  const { data: me } = useMe();
  const [searchParams] = useSearchParams();
  const editGroupId = isOnActionGroupPage
    ? params.id
    : searchParams.get("groupId") || "";
  const solutionId = isOnActionGroupPage ? undefined : params.id;
  const { mutateAsync: mutateCreateActionGroupAsync } = useCreateActionGroup(
    {}
  );
  const { mutateAsync: mutateUpdateActionGroupAsync } = useUpdateActionGroup({
    id: editGroupId,
  });

  const { data: solutionData } = useSolution({
    id: solutionId,
    enabled: !!solutionId,
  });

  const { mutateAsync: mutateCreateAISuggestionGroupAsync } =
    useCreateAIAnalyticsGroups({});
  const { mutateAsync: mutateCreateActionGroupSkillAsync } =
    useCreateActionGroupSkill({});
  const { mutateAsync: mutateUpdateActionGroupSkillAsync } =
    useUpdateActionGroupSkill({});

  const { data: actionGroupForEdit } = useActionGroup({
    id: editGroupId,
    enabled: !!editGroupId,
    user: me?.data.id,
  });

  const queryClient = useQueryClient();
  const { data: meData } = useMe({});
  const { mutateAsync: updateAttachments } = useUpdateAttachments();

  return useCallback(
    async (
      actionGroup: Partial<InitActionGroup>,
      formikHelpers: FormikHelpers<Partial<InitActionGroup>>
    ) => {
      formikHelpers.setSubmitting(true);
      const invalidate = () => {
        queryClient.invalidateQueries([ENDPOINTS.SOLUTIONS]);
        queryClient.invalidateQueries([ENDPOINTS.ACTION_GROUPS]);
        queryClient.resetQueries([ENDPOINTS.ACTION_GROUPS]);
        queryClient.invalidateQueries([ENDPOINTS.ACTION_GROUPS_SKILLS]);
      };
      let actionGroupId = params.id;
      try {
        if (params.id) {
          const payload = new ActionGroupPayload({
            user: meData?.data.id,
            attachmentsType: "actionGroups",
            ...actionGroup,
          });
          const groupPayload = payload.getActionGroup();

          const groupAnalyticsPayload = {
            action_group_title: groupPayload?.title,
            action_group_goals: groupPayload?.description,
            action_group_schedule: groupPayload?.schedule,
            action_group_contact: groupPayload?.contact,
            action_group_skills: Object.assign(
              {},
              ...(actionGroup?.skills?.map((s, index) => {
                const splitCaps = (string: string) =>
                  string
                    .replace(/([a-z])([A-Z]+)/g, (m, s1, s2) => s1 + " " + s2)
                    .replace(
                      /([A-Z])([A-Z]+)([^a-zA-Z0-9]*)$/,
                      (m, s1, s2, s3) => s1 + s2 + s3
                    )
                    .replace(
                      /([A-Z]+)([A-Z][a-z])/g,
                      (m, s1, s2) => s1 + " " + s2
                    );
                const snakeCase = (string: string) =>
                  splitCaps(string)
                    .replace(/\W+/g, " ")
                    .split(/ |\B(?=[A-Z])/)
                    .map((word) => word)
                    .join("_");
                const skillKey = snakeCase(s.skill.label || `[${index}]`);
                return {
                  [skillKey]: Number(s?.timeRequired || 0),
                };
              }) || [])
            ),
            solution_title: solutionData?.data?.title,
            solution_details: solutionData?.data?.description,
            docs: actionGroup?.attachments
              ?.filter((a) => a.type === "file")
              ?.map((a) => (a as unknown as IAttachment<IAsset>)?.asset?.url),
            links: actionGroup?.attachments
              ?.filter((a) => a.type === "link")
              ?.map((a) => (a as unknown as IAttachment)?.data),
            labels: actionGroup?.tags,
          };
          const aiSuggestionsReviewResult =
            await mutateCreateAISuggestionGroupAsync(groupAnalyticsPayload);
          if (
            aiSuggestionsReviewResult.data.feedback_areas.length > 0 ||
            aiSuggestionsReviewResult.data?.more_feedback?.quality ===
              "Useless" ||
            aiSuggestionsReviewResult.data?.more_feedback?.quality === "Poor"
          ) {
            formikHelpers.setTouched({
              title: false,
              description: false,
              schedule: false,
              contact: false,
            });
            document.querySelector("#group-report")?.scrollTo(0, 0);
            formikHelpers.setSubmitting(false);
            return;
          }
          if (editGroupId) {
            if (manage.showIssueFields) {
              const updated = await mutateUpdateActionGroupAsync(
                payload.getActionGroup()
              );
              actionGroupId = updated.data.id;
              showSuccessMessage("Action group is updated!");
            }
            await mutateUpdateActionGroupSkillAsync({
              actionGroup: actionGroupForEdit?.data?.id,
              skills: payload.getSkills(),
            });
            showSuccessMessage("Action group skills are updated!");
          } else {
            if (manage.showIssueFields) {
              const created = await mutateCreateActionGroupAsync({
                ...payload.getActionGroup(),
                solutions: isOnActionGroupPage
                  ? payload.solutions
                  : [params.id],
              });
              actionGroupId = created.data.id;
              showSuccessMessage("Action group is created!");
              if (payload.getSkills().length) {
                await mutateCreateActionGroupSkillAsync({
                  actionGroup: created.data.id,
                  skills: payload.getSkills(),
                });
                showSuccessMessage("Action group skills are created!");
              }
            }
          }
          if (actionGroupId) {
            await updateAttachments(payload.getAttachments(actionGroupId));
            showSuccessMessage("Attachments are updated!");
          }
          success();
          invalidate();
          queryClient.getMutationCache().clear();
        } else {
          formikHelpers.setSubmitting(false);
          showErrorMessage("No solution occurred! Check page!");
        }
      } catch (error: any) {
        formikHelpers.setSubmitting(false);
        formikHelpers.setErrors(error.response.data);
        showErrorMessage("Error occurred! Check form!");
      }

      formikHelpers.setSubmitting(false);
    },
    [
      params.id,
      queryClient,
      meData?.data.id,
      solutionData?.data?.title,
      solutionData?.data?.description,
      mutateCreateAISuggestionGroupAsync,
      editGroupId,
      success,
      manage.showIssueFields,
      mutateUpdateActionGroupSkillAsync,
      actionGroupForEdit?.data?.id,
      mutateUpdateActionGroupAsync,
      mutateCreateActionGroupAsync,
      isOnActionGroupPage,
      mutateCreateActionGroupSkillAsync,
      updateAttachments,
    ]
  );
}
